<template>
  <div></div>
</template>

<script>
import { Loading, Message } from 'element-ui'
import { authLogin, loginUserInfo } from '@/api/user'

export default {
  name: 'AuthLogin',
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async doAuthLogin() {
      let that = this
      const loadingInstance = Loading.service({ fullscreen: true })
      const { origin, auth } = this.$route.query
      if (origin && auth) {
        await authLogin({ origin, auth })
        await loginUserInfo().then(resp => {
          let roles = []

          if (resp.userLevel === 0) {
            roles.push('SUPER')
          }
          window.localStorage.setItem('roles', roles)
          sessionStorage.setItem('userInfo', JSON.stringify(resp))
          that.$store.commit('user/SET_INFO')
        })
        window.localStorage.setItem('origin', window.document.referrer)
        loadingInstance.close()
        this.$router.push('/')
      } else {
        loadingInstance.close()
        Message.error('第三方登录参数缺失')
      }
    }
  },
  mounted() {
    this.doAuthLogin()
  }
}
</script>
